<template>
  <div class="page">
    <van-list
      :finished="finishedShow"
      finished-text="没有更多了"
      class="task-list"
      :immediate-check="false"
      @load="loadTaskList"
    >
      <template #default>
        <van-row
          v-for="task in lovTaskList"
          :key="task.code"
          class="task bdc-b1s-gray"
          @click="detail(task.code)"
        >
          <van-col span="24" class="content">
            <van-row>
              <van-col span="24" class="title"
                >{{ task.name }}
                <van-tag :color="COLOR_M" v-if="task.schoolType === 'GOV'"
                  >公办</van-tag
                ><van-tag :color="COLOR_S1" v-if="task.schoolType === 'CLV'"
                  >民办</van-tag
                ><van-tag :color="COLOR_S2" v-if="task.schoolType === 'ITA'"
                  >国际</van-tag
                ></van-col
              >
            </van-row>
            <div class="amount">
              <van-row>
                <van-col span="12" class="price color-s1">
                  客户服务费：{{ task.price }}元
                </van-col>

                <van-col span="10" offset="2" class="profit">
                  <span class="color-s1"
                    >自推广分成：{{ task.profitRadio }}元</span
                  ></van-col
                >
              </van-row>
              <van-row>
                <van-col span="12" class="price color-s1">
                  推荐人奖励：{{ task.profitReferee }}元
                </van-col>
                <van-col span="10" offset="2" class="profit">
                  <span class="color-s1"
                    >子微客提成：{{ task.profitChild }}元</span
                  ></van-col
                >
              </van-row>
            </div>
          </van-col>
        </van-row>
      </template>
    </van-list>
    <van-row class="no-record" v-if="lovTaskList.length <= 0">
      <van-col span="24">
        <van-image
          width="103"
          height="103"
          :src="require('../../../assets/images/home/no-record.png')"
        ></van-image
      ></van-col>
    </van-row>
    <Tabbar active="home" />
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import { Image, Tag, PullRefresh, List } from 'vant'
import Tabbar from '../common/Tabbar.vue'
import Loading from '../../module/common/Loading.vue'
import Share from '../../module/common/Share.vue'
export default {
  components: {
    Tabbar: Tabbar,
    Loading: Loading,
    Share: Share,
    [Image.name]: Image,
    [Tag.name]: Tag,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List
  },
  data () {
    return {
      loadingShow: false,
      finishedShow: false,
      loginState: 'N',
      keyword: '',
      active: 'rct',
      lovTaskList: [],
      page: { current: 0, size: 10, last: 0 },
      user: {},
      sellerCode: '',
      operatorCode: ''
    }
  },
  mounted () {
    document.title = '婚恋服务'
    var query = this.$route.query
    this.keyword = query.keyword
    this.sellerCode = window.sessionStorage.getItem(this.SESSION_SELLER)
    this.operatorCode = window.sessionStorage.getItem(this.SESSION_OPERATOR)
    this.retrieveSeller()
    this.$refs.share.default('seller')
  },
  methods: {
    async retrieveSeller () {
      var pd = { code: this.sellerCode }
      const { data: res } = await this.$http.post(this.UMS_URL + '/sell/seller/retrieveSeller', this.$qs.stringify(pd))
      if (res.status === '200') {
        if (res.data.state === 'END') {
          this.$dialog.alert({
            title: '提示信息',
            message: '服务已到期或未购买,需购买后继续使用'
          }).then(() => {
            this.$router.push({ path: '/seller/buy' })
          })
        } else {
          this.loadTaskList()
        }
      }
    },
    loadTaskList () {
      if (this.page.last >= this.page.current) {
        this.retrieveTaskList()
      } else {
        this.finishedShow = true
      }
    },
    async retrieveTaskList () {
      this.loadingShow = true
      this.page.current = this.page.current + 1
      var pd = { operatorCode: this.operatorCode, sellerCode: this.sellerCode, keyword: this.keyword, current: this.page.current, size: this.page.size }
      const { data: res } = await this.$http.post(this.BMS_URL + '/love/taskMKT/retrieveTaskSellerList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.lovTaskList = this.lovTaskList.concat(res.data)
        this.page.last = res.page.last
      } else {
        this.lovTaskList = ''
      }
      this.loadingShow = false
    },
    detail (code) {
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      window.sessionStorage.setItem(this.SESSION_BACKURL, window.location.href)
      this.$router.push({ path: '/love/detail', query: { taskCode: code, operator: this.operatorCode, seller: this.sellerCode, referee: user, module: 'SEL' } })
    }
  }
}
</script>
<style lang="less" scoped>
.task-list {
  margin-bottom: 60px;
  .task {
    padding: 8px 10px;
    .title {
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .amount {
      border: 1px solid #f9ded0;
      border-radius: 3px;
      background-color: #fff4ef;
      margin: 5px auto;
      padding: 3px;
      .price {
        height: 20px;
        line-height: 20px;
        text-align: left;
      }
      .profit {
        height: 20px;
        line-height: 20px;
        text-align: left;
      }
    }
    .cert {
      height: 20px;
      line-height: 20px;
      text-align: left;
    }
  }
}
</style>
